<template>
  <v-col sm="12" lg="10" class="center-board">
    <div class="center-board-body tw-px-2 lg:tw-p-10 ">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "CenterDashboard",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.center-board {
  display: flex;
  background: white;
  width: 100%;
  height: available;
  padding: 0 !important;
}
.center-board-body {
  display: flex;
  background: #f5f7f9;
  width: 97%;
  height: available;

  @media screen and (max-width: 1024px){
    width: 100%;
  }
}
</style>
