<template>
  <div class="tw-w-full">
  <div class="tw-w-full tw-flex tw-flex-row header-div tw-items-center" v-if="$vuetify.breakpoint.mdAndUp">
    <v-col sm="2" style="padding-left: 3rem">
      <img
        :src="companyLogo ? companyLogo : '@/assets/bluelogo.png'"
        alt="company logo"
        style="width: auto; height: 30px"
      />
    </v-col>
    <v-icon :color="companyColor" size="24" class="pr-2">
      mdi-magnify
    </v-icon>
    <v-col
      sm="6"
      class="tw-flex tw-items-center tw-flex-row"
      style="border-right: 1px solid rgba(79, 79, 79, 0.06);
     border-left: 1px solid rgba(79, 79, 79, 0.06); height: 60px;"
    >
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            placeholder="Search"
            hide-details
            solo
            flat
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in items" :key="index">
            <v-list-item-title style="color: black">{{
              item
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-icon :color="companyColor" size="24" class="px-5">
      mdi-bell-outline
    </v-icon>
    <v-col
      sm="2"
      class="tw-flex tw-items-center tw-flex-row"
      style="border-right: 1px solid rgba(79, 79, 79, 0.06);
     border-left: 1px solid rgba(79, 79, 79, 0.06); height: 60px;"
    >
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="tw-flex tw-w-full tw-flex-row menu-drop-down"
          >
            <div
              class="tw-flex tw-items-start tw-justify-start tw-flex-col pl-5 tw-w-full"
            >
              <h6
                class="company-name"
                :style="{ color: companyColor ? companyColor : '#004AAD' }"
              >
                {{ staffName }} <v-icon size="18">mdi-chevron-down</v-icon>
              </h6>
              <label class="company-email">{{ staffEmail }}</label>
            </div>
          </div>
        </template>
        <v-list>
          <v-list-item  @click="handleSelect('logout')">
            <v-list-item-title style="color: black">Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <image-avatar :color="companyColor" :avatar="staffName.charAt(0)" :image="staffImage" />
    </v-col>
    <v-col sm="1" class="tw-flex tw-items-center tw-justify-center">
      <v-btn text class="book" :style="{background:companyColor? companyColor:'#004AAD'}" @click="gotoBooking">Book</v-btn>
    </v-col>
  </div>
  <div v-if="$vuetify.breakpoint.smAndDown" class="tw-flex tw-w-full tw-flex-col tw-px-4 tw-py-3 tw-bg-white tw-z-50"
       :class="[drawer ? '' : 'tw-fixed']">
    <div class="tw-flex tw-w-full tw-flex-row tw-justify-between tw-items-center">
        <img
            :src="companyLogo ? companyLogo : '@/assets/bluelogo.png'"
            alt="company logo"
            style="width: auto; height: 30px"
        />

      <div  class="tw-flex tw-items-center tw-justify-end">
        <v-btn v-if="$route.name !== 'TerminalManagerBookings'" text class="book tw-mr-5" :style="{background:companyColor? companyColor:'#004AAD'}" @click="$router.push({name:'TerminalManagerBookings'})">Book</v-btn>

        <v-icon  :color="companyColor" size="24" class="" @click="drawer = !drawer">
          mdi-view-dashboard-outline
        </v-icon>
      </div>
    </div>
    <v-navigation-drawer color="#FDFFFC" v-model="drawer"
                         absolute
                         temporary
                         right>

      <div class="tw-flex tw-px-5 tw-pt-5 tw-pb-2 tw-w-full tw-justify-end" style="background: #FDFFFC">
        <v-icon :color="companyColor" @click="drawer = !drawer">mdi-window-close</v-icon>
      </div>

      <v-col
          class="tw-flex tw-items-center tw-flex-row visible mb-5"
          style="
            border-right: 1px solid rgba(79, 79, 79, 0.06);
            border-left: 1px solid rgba(79, 79, 79, 0.06);
            height: 60px;
          "
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
                class="tw-flex tw-w-full tw-flex-row menu-drop-down"
            >
              <div
                  class="
                    tw-flex tw-items-start tw-justify-start tw-flex-col
                    pl-5
                    tw-w-full
                  "
              >
                <h6
                    class="company-name"
                    :style="{ color: companyColor ? companyColor : '#004AAD' }"
                >
                  {{ staffName }}
                  <v-icon size="18">mdi-chevron-down</v-icon>
                </h6>
                <label class="company-email">{{ staffEmail }}</label>
              </div>
            </div>
          </template>
          <v-list>
            <v-list-item
                @click="selectedAction(item)"
                v-for="(item, index) in items"
                :key="index"
            >
              <v-list-item-title style="color: black">{{
                  item
                }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <image-avatar :avatar="staffName ? staffName.charAt(0) : ''"
                      :color="companyColor" :image="staffImage" />
      </v-col>

      <manager-mobile-side-menu :company-color="companyColor"
                                />
    </v-navigation-drawer>
  </div>
  </div>
</template>

<script>

import ImageAvatar from "../reuseables/ImageAvatar";
import {logout} from "@/services/api/AuthApiService";
import ManagerMobileSideMenu from "@/components/terminalManager/TerminalManagerMobileSideMenu";
export default {
  name: "TerminalManagerDashboardHeader",
  components: {ManagerMobileSideMenu, ImageAvatar },
  props: {
    companyColor: {
      type: String,
      default: "#004AAD",
    },
    staffEmail: {
      type: String,
      default: "example.com",
    },
    // companyEmail: [String],
    staffName: {
      type: String,
      default: "example",
    },
    companyLogo: {
      type: String,
      default:
        "http://res.cloudinary.com/myroadpadi/image/upload/v1642499691/momkq801pfyuyozwdufj.png",
    },
    staffImage: [String]
  },
  data() {
    return {
      items: ["Logout"],
      drawer : false
    };
  },
  watch:{
    drawer: function (val) {
      if (val === true){
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    immediate: true
  },
  methods:{
    handleLogout(){
      logout()
    },
    handleSelect(type){
      if (type === 'logout'){
        this.handleLogout()
      }
    },
    gotoBooking(){
      if (this.$route.name !== 'TerminalManagerBookings') {
        sessionStorage.setItem("terminalDashboardComponent", "booking");
        this.$store.dispatch(
            "terminalDashboardComponent/setCurrentComponent",
            "booking"
        );
        this.$router.push({name: 'TerminalManagerBookings'})
        window.location.reload()
      }
    },
    selectedAction(item) {
      if (item === "Logout") {
        this.handleLogout();
      } else if (item === "Profile") {
        sessionStorage.setItem("terminalDashboardComponent", "settings");
        this.$store.dispatch(
            "terminalDashboardComponent/setCurrentComponent",
            "settings"
        );
        this.$router.push({ name: "Settings" });

        window.location.reload();
      }
    },
  }
};
</script>

<style scoped lang="scss">
.header-div {
  background-color: #ffffff;
  height: 60px;
}

.book {
  border-radius: 6px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  width: auto;
}

.company-name {
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.company-email {
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 10px;
  color: #bdbdbd;
  @media screen and (min-width: 1900px) {
    font-size: 12px;
  }
}
.menu-drop-down {
  width: 60%;
  @media screen and (max-width: 11450px) {
    width: 80%;
  }
}

.v-navigation-drawer__content {
  overflow-x: hidden !important;
}
</style>
