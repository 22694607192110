<template>
  <div class="dashboard">
    <loader v-if="loading" :loading="loading" />
    <div v-else>
    <Header :company-color="companyData.companyColor"
            :company-logo="companyData.companyLogo"
            :staff-email="staffData.email"
            :staff-name="staffData.firstname"
            :staff-image="staffData.profileImage"/>
    <v-col
      sm="12"
      class="tw-flex tw-w-full tw-justify-between  dashboard-body"
      style="background: #F5F7F9">
      <side-menu :company-color="companyData.companyColor" />
      <center-dashboard />
    </v-col>
    </div>
  </div>
</template>

<script>
import Header from "./TerminalManagerDashboardHeader";
import SideMenu from "./TerminalManagerDashboardSideMenu";
import CenterDashboard from "./CenterDashboard";
import {getManagerByEmailOrPhoneNumber, getBookingAdminByEmailOrPhoneNumber} from "@/services/api/APIService";
import {mapGetters} from "vuex";
import Loader from "../reuseables/Loader";
export default {
  name: "TerminalManagerContainer",
  components: {Loader, Header, SideMenu, CenterDashboard },
  data() {
    return {
      staffData:{},
      loading : false
    };
  },
  computed:{
    ...mapGetters('companyProfile',['companyData'])
  },
  methods:{
    async getCompany(){
        if (!this.companyData.transportCompanyVerified) {
          this.$displaySnackbar({
            message: "Your company is not verified",
            success: false,
          });
          await this.$router.push({name: 'TransportCompanyLogin'})
        }
        this.loading = false

    },
    async getStaffData() {
      this.loading = true
      let userData = JSON.parse(sessionStorage.getItem("userData"))
      if (userData && userData.realm_access.roles.includes("TRANSPORT_COMPANY_MANAGER")) {
        await getManagerByEmailOrPhoneNumber(userData.email).then( async res => {
           let data = {}
           data.companyEmail = res.data.transportCompanyEmail
            this.staffData = res.data
            sessionStorage.setItem("staffData",JSON.stringify(res.data))
          await this.$store.dispatch('companyProfile/setCompanyData',data)
              .then(()=>this.getCompany())
        }).catch(error => {
          this.loading = false
          console.log(error);
          this.$displaySnackbar({
            message: error.response.data.details[0],
            success: false,
          });
        })
      }
     else if (userData && userData.realm_access.roles.includes("TRANSPORT_COMPANY_BOOKING_ADMIN")) {
        await getBookingAdminByEmailOrPhoneNumber(userData.email).then( async res => {
          this.staffData = res.data
          let data = {}
          data.companyEmail = res.data.transportCompanyEmail
          sessionStorage.setItem("staffData",JSON.stringify(res.data))
          await this.$store.dispatch('companyProfile/setCompanyData', data)
              .then(() => this.getCompany())
        }).catch(error => {
          this.loading = false
          console.log(error);
          this.$displaySnackbar({
            message: error.response.data.details[0],
            success: false,
          });
        })
      }
    }
  },
  async created() {
    await this.getStaffData()
  }
};
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  background-color: #f5f7f9;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
}

.dashboard-body {
  display: flex;
  min-height: 93.7vh;
  flex-direction: row;
  padding: 0 !important;
  @media screen and (max-width: 1450px) {
    min-height: 93vh;
  }
  @media screen and (max-width: 1370px) {
    min-height: 87vh;
  }
}
</style>
